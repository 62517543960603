// This component will append its children to the page head
import Head from "next/head";
import { Fragment } from "preact";

export const GOOGLE_TRANSLATE_COOKIE_NAME = "googtrans";

export const GoogleTranslate = () => {
  return (
    <Fragment>
      <div id="google_translate_element" />
      <Head>
        <script
          key="google-translate-init-script"
          /*  eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{
            __html: `
function googleTranslateElementInit() {
    new google.translate.TranslateElement({
        pageLanguage: 'en',
        layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL
    }, 'google_translate_element');
}
  `,
          }}
        />
        <script
          key="google-translate-src-lib"
          async
          src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        />
      </Head>
    </Fragment>
  );
};
