import { h } from "preact";

import Head from "next/head";
import { ReactSEOMetaTags } from "react-seo-meta-tags";
import { ROOT_URL } from "../common/constants";

const TITLE = "NYC Vaccine List";
const DESCRIPTION =
  "A volunteer-led effort to help you find a COVID-19 vaccine in and around NYC";

// For now, these are all the same
const TWITTER_SCREENSHOT =
  "https://static.nycvaccinelist.com/Share%20Image/Share-Image-1200x628.png";
const FB_SCREENSHOT =
  "https://static.nycvaccinelist.com/Share%20Image/Share-Image-1200x628.png";
const SITE_SCREENSHOT =
  "https://static.nycvaccinelist.com/Share%20Image/Share-Image-1200x628.png";

export const PageHead = (props) => {
  const { title = "", pagePath } = props;
  const pageTitle = title ? `${title} | ${TITLE}` : TITLE;
  const url = pagePath ? `${ROOT_URL}/${pagePath}` : ROOT_URL;

  return (
    <ReactSEOMetaTags
      render={(elements) => {
        const cleanedSEOMetaTags = elements.map((element) => {
          // Only ld+json raw strings are affected
          // All other elements can be ignored
          if (element.key !== "application/ld+json") {
            return element;
          }

          // This is a JSON containing double quote " characters
          // If we don't un-escape these characters, google will be unable to parse the "quoted" representation.
          // This is a rare case where dangerouslySetInnerHTML is appropriate.
          // https://bleepcoder.com/next-js/229591208/escaping-problem-with-meta-tags
          const ldJSONString = element.props.children;

          return (
            <script
              type="application/ld+json"
              key="application/ld+json"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: ldJSONString }}
            />
          );
        });

        return (
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            {cleanedSEOMetaTags}
            <meta name="description" content={DESCRIPTION} />

            <meta property="og:site_name" content="NYC Vaccine List" />
            <meta name="theme-color" content="#f5f5f6" />
            {/* Webmentions */}
            <link href="https://github.com/hydrosquall" rel="me" />
            <link
              rel="webmention"
              href="https://webmention.io/nycvaccinelist.com/webmention"
            />
            <link
              rel="pingback"
              href="https://webmention.io/nycvaccinelist.com/xmlrpc"
            />
            {/* Icons */}
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="https://static.nycvaccinelist.com/favicon/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="https://static.nycvaccinelist.com/favicon/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="https://static.nycvaccinelist.com/favicon/favicon-16x16.png"
            />
            <link rel="manifest" href="/icons/site.webmanifest" />
            <link
              rel="mask-icon"
              href="https://static.nycvaccinelist.com/favicon/safari-pinned-tab.svg"
              color="#000011"
            />
            <link
              rel="shortcut icon"
              href="https://static.nycvaccinelist.com/favicon/favicon.ico"
            />
            <meta name="msapplication-TileColor" content="#00aba9" />
            <meta
              name="msapplication-config"
              content="https://static.nycvaccinelist.com/favicon/browserconfig.xml"
            />
            <meta
              name="twitter:image:alt"
              content="NYC Vaccine List Logo: Chrysler Building shaped like a vaccine syringe"
            />
          </Head>
        );
      }}
      website={{
        url,
        title: pageTitle,
        image: SITE_SCREENSHOT,
        datePublished: "2021-01-17T13:56:03.123Z",
        language: "en-US",
        author: {
          name: "Cameron Yick",
        },
        description: DESCRIPTION,
      }}
      facebook={{
        title,
        description: DESCRIPTION,
        image: FB_SCREENSHOT,
      }}
      twitter={{
        twitterUser: "@ny_covid",
        title: pageTitle,
        description: DESCRIPTION,
        image: TWITTER_SCREENSHOT,
      }}
    />
  );
};
