export const ROOT_URL = "https://nycvaccinelist.com";

export const DEFAULT_LOCATION_LISTING = {
  name: "See NYC Vaccine Finder for more locations",
  fromCrawler: false,
  buttonLabel: "Check other locations ",
  url: "https://vaccinefinder.nyc.gov/",
  publicNotes:
    "NYCVaccineList.com does not cover all vaccine locations in the NYC area. There may be vaccines available at other locations. While we work to add appointment information for more locations, you can use New York City's official Vaccine Finder to manually search for locations with appointments available.",
  shouldTranslate: true,
};
