import Cookies from "js-cookie";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, memo } from "preact/compat";
import { useCallback, useEffect, useMemo, useState } from "preact/hooks";

import Logo from "../svg/image-trademark.svg";
import Vercel from "../svg/powered-by-vercel.svg";
import {
  GOOGLE_TRANSLATE_COOKIE_NAME,
  GoogleTranslate,
} from "./GoogleTranslate";
import { ShareButtons } from "./ShareButtons";

const sanitizeLang = (maybeLang) => {
  //  Thank you! https://stackoverflow.com/a/51184024/5129731
  // https://cloud.google.com/translate/docs/languages
  let cleanLang = maybeLang.slice(0, 5).toLocaleLowerCase(); // simple guard against weirdly long language codes. Max is 5 chars
  // patches for chinese. Note it's the only lang with uppercase
  if (cleanLang === "cn" || cleanLang === "zh") {
    cleanLang = "zh-CN";
  } else if (cleanLang === "tw" || cleanLang === "zh-tw") {
    cleanLang = "zh-TW";
  }
  return cleanLang;
};

const TranslateButton = () => {
  const { query } = useRouter();
  const { lang } = query;
  const [hasCheckedUrl, sethasCheckedUrl] = useState(
    Boolean(Cookies.get(GOOGLE_TRANSLATE_COOKIE_NAME))
  );

  // Load via URL once at runtime
  useEffect(() => {
    if (lang) {
      const cleanLang = sanitizeLang(lang);
      Cookies.set(GOOGLE_TRANSLATE_COOKIE_NAME, `/en/${cleanLang}`, {
        expires: 999,
      });
    }

    sethasCheckedUrl(true);
  }, [lang]);

  return <div class="mt-6">{hasCheckedUrl && <GoogleTranslate />}</div>;
};

// Shortcode directory: https://developers.google.com/admin-sdk/directory/v1/languages
const NYC_TOP_LANGUAGES = [
  {
    label: "español",
    shortcode: "es",
  },
  {
    label: "中文 (简体)",
    shortcode: "zh",
  },
  {
    label: "中文 (繁体)",
    shortcode: "zh-TW",
  },
  {
    label: "français",
    shortcode: "fr",
  },
  {
    label: "русский",
    shortcode: "ru",
  },
  {
    label: "kreyòl ayisyen",
    shortcode: "ht",
  },
  {
    label: "한국어",
    shortcode: "ko",
  },
  {
    label: "polski",
    shortcode: "pl",
  },
  {
    label: "عربى",
    shortcode: "ar",
  },
  {
    label: "اردو",
    shortcode: "ur",
  },
  {
    label: "বাঙালি",
    shortcode: "bn",
  },
  {
    label: "english",
    shortcode: "en",
  },
];

// Utils
const FEEDBACK_PROMPTS = [
  "I wish this site would...",
  "I noticed a data issue...",
  // "This site helped me find a vaccine for...", # comment to avoid confusion, via Dudie
];

const choose = (array) => array[Math.floor(Math.random() * array.length)];

const chooseN = (array, n) => {
  shuffleArray(array);
  return array.slice(0, n);
};

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

const MAINTAINER_NAMES = [
  "Sharon",
  "Dan B.",
  "Cameron Y.",
  "Dudie S.",
  "Gabe P.",
  "Michael K.",
  "Michael P.",
  "Carolyn R.",
  "Kenisha K.",
  "Graham L.",
  "Angelica C.",
  "Doug D.",
  "Natasha P.",
];

const Maintainers = (props) => {
  const { numPeople = 3 } = props;

  const contributorString = chooseN(MAINTAINER_NAMES, numPeople).map(
    (person, i) => {
      if (i === numPeople - 1) {
        return <Fragment>and {person}</Fragment>;
      }
      return <Fragment>{person}, </Fragment>;
    }
  );

  return contributorString;
};

const FeedbackForm = () => {
  const prompt = useMemo(() => choose(FEEDBACK_PROMPTS), []);
  const [placeholder, setPlaceholder] = useState(prompt);
  const [messageValue, setMessageValue] = useState("");
  const [emailValue, setEmailValue] = useState("");

  const handleMessageChange = useCallback((e) => {
    setMessageValue(e.target.value);
  }, []);

  const handleEmailChange = useCallback((e) => {
    setEmailValue(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (messageValue.length < 3) {
        window.alert("Oops, you might did you mean to send a message first?");
        return; // basic validation
      }

      const xhr = new XMLHttpRequest();
      const fd = new FormData();

      fd.append("email", emailValue);
      fd.append("message", messageValue);

      xhr.addEventListener("load", () => {
        if (xhr.status === 200) {
          setMessageValue("");
          setEmailValue("");
          setPlaceholder("Thanks for the feedback!");
        } else {
          alert("Oops! Something went wrong. Let's try again.");
        }
      });
      xhr.addEventListener("error", () => {
        alert("Oops! Something went wrong. Give it another try.");
      });
      xhr.open("POST", "https://api.nycvaccinelist.com/feedback");
      xhr.send(fd);
    },
    [emailValue, messageValue]
  );

  return (
    <form onSubmit={handleSubmit} class="relative py-2 flex flex-col">
      <label
        class="my-2 uppercase tracking-wide font-bold text-sm text-gray-700"
        for="email-field"
      >
        Email (optional)
      </label>
      <input
        onChange={handleEmailChange}
        value={emailValue}
        class="border py-2 px-4 text-grey-darkest md:mr-2 mb-2 bg-white hover:outline-black focus:border-blue-300 shadow-inner"
        type="email"
        size="120"
        name="email"
        id="email-field"
        placeholder="hello@mail.com (optional)"
      />
      <label
        class="my-2 uppercase tracking-wide font-bold text-sm text-gray-700"
        for="feedback-field"
      >
        Feedback
      </label>
      <textarea
        onChange={handleMessageChange}
        value={messageValue}
        class="border py-2 px-4 text-grey-darkest md:mr-2 bg-white hover:outline-black focus:border-blue-300 shadow-inner"
        type="text"
        size="120"
        name="feedback-field"
        id="feedback-field"
        placeholder={placeholder}
      />
      <button
        class="mt-4 px-4 bg-linkBlueBg hover:opacity-75 text-white font-bold py-2 px-4 rounded mt-2 md:mr-2 cursor-pointer uppercase"
        style={{ height: "100%" }}
        onClick={handleSubmit}
      >
        Submit
      </button>
    </form>
  );
};

const FeedbackFormSection = () => {
  return (
    <div class="relative px-6 py-4 flex flex-col md:w-8/12" id="feedback-form">
      <p class="mb-2 uppercase tracking-wide font-bold text-lg text-gray-900 text-lg">
        We'd love to hear from you
      </p>
      <p class="text-gray-700 text-md leading-6 pb-2">
        We are an independent group of volunteers, and not affiliated with the
        government or organizations providing vaccines. Our goal is to find and
        share information about vaccine availability around NY. Unfortunately,
        we are not able to make, change, or cancel appointments.
      </p>
      <p class="text-gray-700 text-md leading-6 pb-2">
        We read and appreciate every bit of feedback, but are unable to respond
        to every message. For answers to common questions, please see our{" "}
        <Link href="/faq">
          <a className="underline text-linkBlue hover:text-blue-800 visited:text-purple-600">
            FAQ
          </a>
        </Link>
        .
      </p>
      <p class="text-gray-700 text-md leading-6">
        Do you need someone to schedule an appointment for you? Our friends at
        Epicenter-NYC are happy to help!{" "}
        <a
          className="underline text-linkBlue hover:text-blue-800 visited:text-purple-600"
          target="_blank"
          rel="noopener noreferrer"
          href="https://airtable.com/shr7lRDaT8BroQKGk"
        >
          Fill out this form{" "}
        </a>{" "}
        and someone will get back to you.
      </p>

      <FeedbackForm />
    </div>
  );
};

// Footer revamp based on https://w3hubs.com/Responsive-Footer-Using-Tailwindcss/
export const Footer = memo(() => {
  return (
    <footer class="bg-gray-100">
      <div class="relative py-3 container mx-auto ">
        <FeedbackFormSection />
        <div class="relative px-6 pt-2">
          <span class="text-gray-700 text-lg">
            Find us on Twitter for updates:{" "}
            <a
              href="https://twitter.com/ny_covid"
              class="underline text-linkBlue hover:text-blue-800 visited:text-purple-600"
            >
              @ny_covid
            </a>
          </span>
        </div>
      </div>

      <div class="bg-gray-100 pt-4 pb-8">
        <div class="container text-lg mx-auto">
          <div class="px-6">
            <p class="bottom text-gray-800">
              Made with ❤️ by
              <Link href="/about#team">
                <a className="text-pink-700 hover:text-cyan-500">
                  {" "}
                  volunteers like <Maintainers />
                </a>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap bg-indigo-100 p-4 pt-8">
        <div class="flex flex-wrap mb-4 w-full container mx-auto">
          <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 mx-6 py-4">
            <Link href="/">
              <Logo
                class="cursor-pointer hover:opacity-75"
                width="250px"
                title="NYC Vaccine List Logo: Chrysler Building shaped like a vaccine syringe"
              />
            </Link>
            <div class="py-6" id="translate-me">
              <div class="text-gray-700 pb-2">
                is available in your language:{" "}
              </div>
              <p class="leading-8">
                {NYC_TOP_LANGUAGES.map((opt, i) => {
                  return (
                    <Fragment>
                      <a
                        href={`?lang=${opt.shortcode}`}
                        class="notranslate text-blue-900 hover:text-blue-600"
                      >
                        {opt.label}
                      </a>
                      {i !== NYC_TOP_LANGUAGES.length - 1 && (
                        <span className="text-gray-800 font-bold"> · </span>
                      )}
                    </Fragment>
                  );
                })}
              </p>
              <TranslateButton />
            </div>
          </div>
          <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 mx-4">
            <h3 class="text-xl lg:text-2xl py-4">Getting Involved</h3>
            <div class="text-gray-800 leading-8">
              You can help us help others find vaccines by sharing this page
              with your community (especially those who don't use social media).
            </div>
            <div class="py-2 mt-4">
              <ShareButtons />
            </div>
          </div>

          <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 ml-4">
            <h3 class="text-xl lg:text-2xl py-4">Site Map</h3>
            <ul class="leading-8">
              <li>
                <Link href="/">
                  <a class="text-blue-800 hover:text-blue-500 visited:text-purple-600">
                    {" "}
                    Vaccine Appointment Listings
                  </a>
                </Link>
              </li>
              <li class="text-blue-800 hover:text-blue-500 visited:text-purple-600">
                <Link href="/faq">
                  <a> Frequently Asked Questions</a>
                </Link>
              </li>
              <li class="text-blue-800 hover:text-blue-500 visited:text-purple-600">
                <Link href="/about">
                  <a> About</a>
                </Link>
              </li>
              <li class="text-blue-800 hover:text-blue-500 visited:text-purple-600">
                <Link href="/how-it-works">
                  <a> How it works</a>
                </Link>
              </li>
              <li class="text-blue-800 hover:text-blue-500 visited:text-purple-600">
                <Link href="/walk-ups">
                  <a> Walk-up sites</a>
                </Link>
              </li>
              {/* <li class="text-blue-800 hover:text-blue-500 visited:text-purple-600">
                <Link href="/disclaimer">
                  <a> Legal Disclaimer</a>
                </Link>
              </li> */}
            </ul>
            <h3 class="text-xl lg:text-2xl py-4 ">Disclaimer</h3>
            <p class="text-gray-700">
              This site was put together by volunteers using our best efforts to
              assemble readily available data from public sources. This site
              does not provide medical advice, nor does it provide any type of
              technical advice. nycvaccinelist.com is not responsible for any
              errors or omissions. nycvaccinelist.com provides this information
              on an “as is” basis, with no representations or warranties of any
              kind, express or implied, including with respect to accuracy,
              completeness, quality, non-infringement, merchantability or
              fitness for a particular purpose. nycvaccinelist.com will not be
              liable for any damages of any kind arising from the use of, or
              reliance on, any information made available on this site.
            </p>
          </div>
        </div>
      </div>

      <div class="bg-gray-800 py-4">
        <div class="container text-lg mx-auto py-2">
          <div class="px-8">
            <div class="py-2" style={{ width: 212 }}>
              <a
                href={`https://vercel.com?utm_source=nyc-vaccine-list&utm_campaign=oss`}
                rel="noopener noreferrer"
                target="_blank"
                aria-label="Site hosted by Vercel"
              >
                <Vercel />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
});
