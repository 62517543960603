import { h } from "preact";

import Link from "next/link";
import { memo } from "preact/compat";
import { useState } from "preact/hooks";
import { Icon } from "semantic-ui-react";

import Logo from "../svg/image-trademark.svg";
import { ActiveLink } from "./ActiveLink";

export const Navbar = memo(() => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <div>
      <header
        class="relative flex flex-wrap items-center justify-between px-2 sm:py-2 bg-gray-100"
        role="navigation"
        aria-label="Main"
      >
        <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div class="w-full relative flex justify-between sm:w-auto sm:static sm:block sm:justify-start items-center">
            <Link class="leading-relaxed mr-4 py-2 whitespace-no-wrap" href="/">
              <Logo
                class="cursor-pointer hover:opacity-75"
                width="100%"
                height="77"
                title="NYC Vaccine List Logo: Chrysler Building shaped like a vaccine syringe"
              />
            </Link>
            <button
              class="text-gray-900 cursor-pointer text-lg leading-none px-3 py-3 border border-solid border-transparent sm:hidden outline-none focus:outline-none navbar-link text-bold bg-gray-700 text-linkBlueFg rounded shadow-xl"
              type="button"
              style={{ height: "100%" }}
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              menu
            </button>
          </div>
          <nav
            class={`sm:flex flex-grow justify-end${
              navbarOpen ? " flex" : " hidden"
            }`}
          >
            <ul class="flex flex-col sm:flex-row list-none sm:ml-auto mt-6">
              <li class="nav-item">
                <ActiveLink
                  href="/"
                  activeClassName="navbar-link text-bold bg-linkBlueBg text-linkBlueFg rounded shadow-md"
                >
                  <a class="navbar-link">Listings</a>
                </ActiveLink>
              </li>
              <li class="nav-item">
                <ActiveLink
                  href="/walk-ups"
                  activeClassName="navbar-link text-bold bg-linkBlueBg text-linkBlueFg rounded shadow-md"
                >
                  <a class="navbar-link">Walk-ups</a>
                </ActiveLink>
              </li>
              <li class="nav-item">
                <ActiveLink
                  href="/faq"
                  activeClassName="navbar-link bg-linkBlueBg text-linkBlueFg rounded shadow-md"
                >
                  <a class="navbar-link">FAQ</a>
                </ActiveLink>
              </li>
              <li class="nav-item">
                <ActiveLink
                  href="/about"
                  activeClassName="navbar-link bg-linkBlueBg text-linkBlueFg rounded shadow-md"
                >
                  <a class="navbar-link"> About</a>
                </ActiveLink>
              </li>
              <li class="nav-item">
                <ActiveLink href="#feedback-form">
                  <a class="navbar-link"> Feedback</a>
                </ActiveLink>
              </li>
              <li>
                <div class="flex items-center cursor-pointer hover:bg-gray-200 rounded">
                  <a href="#translate-me" class="navbar-link">
                    Translate
                  </a>
                  <Icon name="globe" color="blue" fitted />{" "}
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
});
