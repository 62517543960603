import { h } from "preact";
import { Navbar } from "@/components/Navbar";
import { Footer } from "@/components/Footer";
import { Fragment } from "preact/compat";

export const LayoutWrapper = (props) => {
  return (
    <Fragment>
      <div class="min-h-screen bg-gray-100 flex flex-col text-gray-900 leading-tight">
        <Navbar />
        {props.children}
      </div>
      <Footer />
    </Fragment>
  );
};

export default LayoutWrapper;
