//https://github.com/vercel/next.js/blob/canary/examples/active-class-name/components/ActiveLink.js
import { useRouter } from "next/router";
import Link from "next/link";
// import { Children } from "preact/compat";
import { h, cloneElement } from "preact";

export const ActiveLink = ({ children, activeClassName, ...props }) => {
  const { asPath } = useRouter();
  const child = children; // not needed in preact Children.only(children);
  const childClassName = child.props.className || "";

  const className =
    asPath === props.href || asPath === props.as
      ? `${childClassName} ${activeClassName}`.trim()
      : childClassName;

  return (
    <Link {...props}>
      {cloneElement(child, {
        className: className || null,
      })}
    </Link>
  );
};
